export function formatNumber(value: number) {
  return (+value)
    .toFixed(2)
    .replace(/\.00/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export function formatCurrency(value: string) {
  const currencies: { [k: string]: string } = {
    CZK: 'Kč',
    USD: '$',
    EUR: '€',
    ZAR: 'R',
    HUF: 'Ft',
  }
  return currencies[value] || 'Kč'
}

export function formatMoney(moneyObject: Money | null) {
  if (moneyObject == null) {
    return ''
  }
  const currencyString = formatCurrency(moneyObject.currency)
  const moneyString = moneyObject.round
    ? Math.round(moneyObject.value as number).toString()
    : formatNumber(Number(moneyObject.value))
  switch (moneyObject.currency) {
    case 'CZK':
    case 'HUF':
      return `${moneyString.replace('.', ',')} ${currencyString}`
    default:
      return `${currencyString}${moneyString}`
  }
}
